import { Controller } from 'stimulus';

type Payload = {
  processing: boolean;
};

export async function timeout(ms: number, signal: AbortSignal) {
  return new Promise<void>((resolve, reject) => {
    let timer: ReturnType<typeof setTimeout>;

    const onAbort = () => {
      clearTimeout(timer);
      reject(signal.reason);
    };

    const onSuccess = () => {
      signal.removeEventListener('abort', onAbort);
      resolve();
    };

    timer = setTimeout(onSuccess, ms);
    signal.addEventListener('abort', onAbort);
  });
}

async function isProcessing(url: string, signal: AbortSignal): Promise<boolean> {
  const response = await fetch(url, { signal });
  const payload = (await response.json()) as Payload;
  return payload.processing;
}

function isAbortError(error: unknown): error is DOMException {
  return error instanceof DOMException && error.name === 'AbortError';
}

export default class extends Controller {
  static values = {
    url: String,
    interval: { type: Number, default: 1000 },
  };

  declare urlValue: string;

  declare intervalValue: number;

  #abortController!: AbortController;

  connect(): void {
    this.#abortController = new AbortController();
    void this.#startPolling();
  }

  disconnect(): void {
    this.#abortController.abort();
  }

  async #startPolling(): Promise<void> {
    const { signal } = this.#abortController;

    try {
      // eslint-disable-next-line no-await-in-loop
      while (await isProcessing(this.urlValue, signal)) {
        // eslint-disable-next-line no-await-in-loop
        await timeout(this.intervalValue, signal);
      }

      window.location.reload();
    } catch (error: unknown) {
      if (!isAbortError(error)) {
        throw error;
      }
    }
  }
}
