import { DataFetchProvider, FlowStateProvider, Step } from '@betterment/js-runtime';
import { BrowserRouter as Router } from 'react-router-dom';

import { DoneStep } from '../components/TotpSetupFlow/DoneStep';
import { StartStep } from '../components/TotpSetupFlow/StartStep';
import { VerifyStep } from '../components/TotpSetupFlow/VerifyStep';
import useSettingsUrl from '../Shared/hooks/useSettingsUrl';
import { buildAppPath } from '../utils/path-helpers';

const TotpSetupFlow = () => {
  const baseName = buildAppPath('/better_mfa/totp_setup');
  const settingsUrl = useSettingsUrl();

  return (
    <DataFetchProvider>
      <Router>
        <FlowStateProvider basePath={baseName} returnToPath={settingsUrl}>
          <Step name="start" component={StartStep} />
          <Step name="verify" component={VerifyStep} />
          <Step name="done" component={DoneStep} />
        </FlowStateProvider>
      </Router>
    </DataFetchProvider>
  );
};

export default TotpSetupFlow;
