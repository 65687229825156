import React from 'react';

import { FlowStateProvider, Step } from '@betterment/js-runtime';

import { InitialProps } from './data';
import { SmsStartStep } from './SmsStartStep';
import { SmsVerifyStep } from './SmsVerifyStep';
import { TotpVerifyStep } from './TotpVerifyStep';

import { Modes, SmsDeliveryMethods } from '../../Shared/data';

const Controller: React.FC<InitialProps> = ({ returnToPath, mfaPhoneNumber, mfaMode }) => {
  if (mfaMode === Modes.totp) {
    return (
      <FlowStateProvider returnToPath={returnToPath}>
        <Step name="verify" component={TotpVerifyStep} />
      </FlowStateProvider>
    );
  }
  const initialValues = {
    deliveryMethod: SmsDeliveryMethods.sms,
    mfaPhoneNumber,
  };
  return (
    <FlowStateProvider initialValues={initialValues} returnToPath={returnToPath}>
      <Step name="start" component={SmsStartStep} />
      <Step name="verify" component={SmsVerifyStep} />
    </FlowStateProvider>
  );
};

export default Controller;
