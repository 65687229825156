import React, { MouseEvent, useState } from 'react';

import { useFlowState, useMutation } from '@betterment/js-runtime';
import {
  Box,
  Button,
  CenteredLayout,
  Dialog,
  FormikField,
  Heading,
  IconButton,
  Input,
  Logo,
  PancakeStack,
  Text,
  TextColorToken,
  WorkflowHeader,
} from '@betterment/samba-react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Helmet } from 'react-helmet';

import { getAppConfig } from '../../../b4b/config';
import {
  NON_PROD_ENVIRONMENTS,
  SmsDeliveryMethods,
  VERIFICATION_CODE_LENGTH,
  VerificationCodeSchema,
} from '../../Shared/data';
import URLBuilder from '../../Shared/url-builder';
import { mapFirstApiErrorMsgToFormikAttribute } from '../../utils/form-helpers';

const initialResendMessageState: {
  messageColor: TextColorToken;
  messageContent: string;
} = {
  messageColor: 'text',
  messageContent: '',
};

const VerifyStep: React.FC = () => {
  const appConfig = getAppConfig();
  const { sentryEnvironment } = appConfig;

  const {
    flowState: { values },
    goToPreviousStep,
    goToNextStep,
  } = useFlowState<{
    mfaPhoneNumber: string;
    deliveryMethod: SmsDeliveryMethods;
  }>();
  const [resendMessageState, setResendMessageState] = useState(initialResendMessageState);
  const [verifyCode, { loading: isConfirmingCode }] = useMutation(
    URLBuilder.postLoginChallengeConfirmation.absolute(),
    {
      method: 'post',
    }
  );
  const [resend, { loading: isResendingCode }] = useMutation(URLBuilder.postLoginChallengeResend.absolute(), {
    method: 'post',
  });

  const handleResend = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setResendMessageState(initialResendMessageState);
    const response = await resend({ deliveryMethod: values.deliveryMethod });
    if (response.errors) {
      setResendMessageState({
        messageColor: 'text.critical',
        messageContent: mapFirstApiErrorMsgToFormikAttribute(response.errors, 'base').base,
      });
      return;
    }
    setResendMessageState({
      messageColor: 'text.success',
      messageContent: 'Code successfully resent.',
    });
  };

  const onSubmit = async (submitValues: { code: string }, { setErrors }: FormikHelpers<{ code: string }>) => {
    const response = await verifyCode(submitValues);
    if (response.errors) {
      setErrors(mapFirstApiErrorMsgToFormikAttribute(response.errors, 'code'));
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <Helmet>
        <title>Login challenge</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading>
              <IconButton
                label="back to previous step"
                name="LeftArrow"
                spacing="default"
                variant="ghost-alt"
                onClick={goToPreviousStep}
              />
            </WorkflowHeader.Leading>
            <WorkflowHeader.Title>
              <Logo type="signature" css={{ height: 'size.200' }} />
            </WorkflowHeader.Title>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout
            size="medium"
            css={{
              gap: 'space.200',
              padding: 'space.400',
              alignItems: 'stretch',
            }}
          >
            <Heading as="h1">Enter verification code</Heading>
            <Box css={{ marginBottom: 'space.100' }}>
              {values.deliveryMethod === 'sms' ? (
                <Text>
                  We just sent a text message with a {VERIFICATION_CODE_LENGTH}
                  -digit code to {values.mfaPhoneNumber}.
                </Text>
              ) : (
                <Text as="p">
                  We just called your phone {values.mfaPhoneNumber} to share a {VERIFICATION_CODE_LENGTH}-digit code.
                </Text>
              )}
            </Box>

            {NON_PROD_ENVIRONMENTS.includes(sentryEnvironment) && <p>Use 123456 for development.</p>}

            <Box>
              <Formik
                initialValues={{ code: '' }}
                validationSchema={VerificationCodeSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                <Form>
                  <FormikField name="code">
                    <FormikField.Label>Verification code</FormikField.Label>
                    <FormikField.Control>
                      <Input />
                    </FormikField.Control>
                    <FormikField.ErrorMessage />
                  </FormikField>
                  <Box css={{ marginBottom: 'space.500' }}>
                    <Text color={resendMessageState.messageColor}>{resendMessageState.messageContent}</Text>
                  </Box>
                  <Box css={{ marginBottom: 'space.500' }}>
                    <Button type="submit" disabled={isConfirmingCode} css={{ marginBottom: 'space.200' }}>
                      Verify
                    </Button>
                  </Box>
                  <Box css={{ marginY: 'space.200' }}>
                    <Button
                      variant="linkPrimary"
                      onClick={(event: MouseEvent<HTMLButtonElement>) => void handleResend(event)}
                      disabled={isResendingCode}
                      type="button"
                    >
                      Resend code
                    </Button>
                  </Box>
                  <Box>
                    <Text>
                      Not able to verify?{' '}
                      <Dialog overlay="default">
                        <Dialog.Trigger asChild={true}>
                          <Button spacing="default" variant="linkPrimary">
                            We can help.
                          </Button>
                        </Dialog.Trigger>
                        <Dialog.Content
                          css={{
                            p: 'space.400',
                          }}
                          size="large"
                        >
                          <Dialog.Title asChild={true}>
                            <Heading as="h1" variant="title200" css={{ marginY: 'space.200' }}>
                              I'm not able to submit a verification code
                            </Heading>
                          </Dialog.Title>
                          <Dialog.Description asChild={true}>
                            <Text variant="text100">
                              If your phone was lost or stolen, please email us at plansupport@betterment.com, and we
                              will help you log in once we verify your identity.
                            </Text>
                          </Dialog.Description>
                        </Dialog.Content>
                      </Dialog>
                    </Text>
                  </Box>
                </Form>
              </Formik>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { VerifyStep };
