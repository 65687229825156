/// <reference types="vite/client" />

import '~/core/scripts/jquery';
import { startCacheWorker } from '@betterment/js-runtime';
import * as scLegacyInitializers from '@betterment/style-closet-legacy';
import Rails from '@rails/ujs';

import { getAppConfig } from '../config';
import { startStimulus } from '../controllers';
import { startTurbo } from '../turbo';

import '~/core/scripts/toggle_form_section';
import '~/core/scripts/radio_button_display_toggle';
import '../scripts/donut';
import '../scripts/links';
import '../scripts/participant_eligibility_update';
import '../scripts/year_end_utility_use_ach_amount';
import '../scripts/form_refresh';
import '../scripts/nested_form_fields_tooltips';
import '../scripts/analytics';
import '~/core/vendor/jQuerySpinnerPlugin';
import '~/core/vendor/nested-form-fields';

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  Rails.start();
}

Object.values(scLegacyInitializers).forEach(initializer => {
  initializer();
});

startTurbo();
void startStimulus(getAppConfig());
startCacheWorker();
