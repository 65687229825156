import { Controller } from 'stimulus';

type TurboFrame = HTMLElement & { src: string };
type UpdateEvent = HTMLElementEventMap['autocomplete:change'];

export default class extends Controller {
  declare frameTarget: TurboFrame;

  declare urlTemplateValue: string;

  static targets = ['frame'];

  static values = { urlTemplate: String };

  update(event: UpdateEvent): void {
    const { value } = event.detail;

    if (value) {
      const src = this.urlTemplateValue.replace('{value}', value);
      this.frameTarget.setAttribute('src', src);
    } else {
      this.frameTarget.removeAttribute('src');
      this.frameTarget.replaceChildren();
    }
  }
}
