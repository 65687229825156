import React, { useState } from 'react';

import { useFlowState, useMutation } from '@betterment/js-runtime';
import {
  Box,
  Button,
  CenteredLayout,
  Dialog,
  Heading,
  Logo,
  PancakeStack,
  Text,
  TextColorToken,
  WorkflowHeader,
} from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import { getAppConfig } from '../../../b4b/config';
import { NON_PROD_ENVIRONMENTS, SmsDeliveryMethods, VERIFICATION_CODE_LENGTH } from '../../Shared/data';
import URLBuilder from '../../Shared/url-builder';
import { mapFirstApiErrorMsgToFormikAttribute } from '../../utils/form-helpers';

const initialErrorState: {
  messageColor: TextColorToken;
  messageContent: string;
} = {
  messageColor: 'text',
  messageContent: '',
};

const StartStep: React.FC = () => {
  const { sentryEnvironment } = getAppConfig();
  const {
    flowState: { values },
    goToNextStep,
  } = useFlowState<{
    mfaPhoneNumber: string;
    deliveryMethod: SmsDeliveryMethods;
  }>();

  const [errorState, setErrorState] = useState(initialErrorState);
  const [sendSmsCode, { loading: isSendingCode }] = useMutation(URLBuilder.postLoginChallengeSend.absolute(), {
    method: 'post',
  });
  const sendVerificationCode = async (deliveryMethod: SmsDeliveryMethods) => {
    const response = await sendSmsCode({ deliveryMethod });
    if (response.errors) {
      setErrorState({
        messageColor: 'text.critical',
        messageContent: mapFirstApiErrorMsgToFormikAttribute(response.errors, 'base').base,
      });
    } else {
      goToNextStep({ deliveryMethod });
    }
  };

  return (
    <>
      <Helmet>
        <title>Login challenge</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading>
              <Logo type="signature" css={{ height: 'size.300' }} />
            </WorkflowHeader.Leading>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1">Let's verify your account</Heading>
            <Box css={{ marginY: 'space.50' }}>
              <Text>
                We'll send a {VERIFICATION_CODE_LENGTH}-digit verification code to your phone number via text message:{' '}
                {values.mfaPhoneNumber}.
              </Text>

              {NON_PROD_ENVIRONMENTS.includes(sentryEnvironment) && (
                <Text as="p" css={{ marginTop: 'space.200' }}>
                  In development no messages are sent, please proceed and the verification code will default to 123456.
                </Text>
              )}
            </Box>
            <Box>
              <Text as="span" color="text.weak" variant="text50">
                Message and data rates may apply.
              </Text>
            </Box>
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button disabled={isSendingCode} onClick={() => void sendVerificationCode(SmsDeliveryMethods.sms)}>
                Text me
              </Button>
            </Box>

            <Box>
              <Text color={errorState.messageColor}>{errorState.messageContent}</Text>
              <Box css={{ marginY: 'space.200' }}>
                <Button
                  variant="linkPrimary"
                  disabled={isSendingCode}
                  onClick={() => void sendVerificationCode(SmsDeliveryMethods.voice)}
                >
                  Call me instead
                </Button>
              </Box>
              <Box css={{ marginY: 'space.200' }}>
                <Text>
                  Not able to verify?{' '}
                  <Dialog overlay="default">
                    <Dialog.Trigger asChild={true}>
                      <Button spacing="default" variant="linkPrimary">
                        We can help.
                      </Button>
                    </Dialog.Trigger>
                    <Dialog.Content
                      css={{
                        p: 'space.400',
                      }}
                      size="large"
                    >
                      <Dialog.Title asChild={true}>
                        <Heading as="h1" variant="title200" css={{ marginY: 'space.200' }}>
                          I'm not able to submit a verification code
                        </Heading>
                      </Dialog.Title>
                      <Dialog.Description asChild={true}>
                        <Text variant="text100">
                          If your phone was lost or stolen, please email us at plansupport@betterment.com, and we will
                          help you log in once we verify your identity.
                        </Text>
                      </Dialog.Description>
                    </Dialog.Content>
                  </Dialog>
                </Text>
              </Box>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { StartStep };
