import { route } from '@betterment/js-runtime';

export default {
  getTotpSetupFlow() {
    return '/better_mfa/totp_setup/start';
  },
  getSmsSetupFlow() {
    return '/better_mfa/sms_setup/start';
  },

  getSettings() {
    return '/better_mfa/api/v1/settings';
  },
  // Totp Setup
  getTotpSetupNew: route('/better_mfa/api/v1', ['totp_setup', 'new']),
  postTotpSetupConfirmation: route('/better_mfa/api/v1', ['totp_setup']),
  // Login Challenges
  postLoginChallengeConfirmation: route('/better_mfa/api/v1', ['login_challenge', 'confirmation']),
  postLoginChallengeResend: route('/better_mfa/api/v1', ['login_challenge', 'resend']),
  postLoginChallengeSend: route('/better_mfa/api/v1', ['login_challenge', 'send']),
  // SMS Setup
  postSmsSetupCodeSend: route('/better_mfa/api/v1', ['sms_setup', 'send']),
  postSmsSetupCodeResend: route('/better_mfa/api/v1', ['sms_setup', 'resend']),
  postSmsSetupCodeConfirmation: route('/better_mfa/api/v1', ['sms_setup', 'confirmation']),
  // Sudo Challenges
  postSudoChallengeSend: route('/better_mfa/api/v1', ['sudo_challenge', 'send']),
  postSudoChallengeResend: route('/better_mfa/api/v1', ['sudo_challenge', 'resend']),
  postSudoChallengeConfirmation: route('/better_mfa/api/v1', ['sudo_challenge', 'confirmation']),
  // Trusted Devices
  postTrustedDevice: route('/better_mfa/api/v1', ['trusted_device']),
};
