import { useEffect, useState } from 'react';

import urlBuilder from '../url-builder';

// Define the response structure
interface SettingsResponse {
  data: {
    settings_url: string;
  };
  errors?: string;
}

const useSettingsUrl = () => {
  const [settingsUrl, setSettingsUrl] = useState<string>('/');

  useEffect(() => {
    const fetchSettingsUrl = async () => {
      try {
        const response = await fetch(urlBuilder.getSettings());

        if (!response.ok) {
          throw new Error('Failed to fetch settings');
        }

        const data = (await response.json()) as SettingsResponse;

        if (data.errors) {
          setSettingsUrl('/');
        } else {
          setSettingsUrl(data.data.settings_url || '/');
        }
      } catch (error) {
        setSettingsUrl('/');
      }
    };

    void fetchSettingsUrl(); // Ensure async function is executed properly
  }, []);

  return settingsUrl;
};

export default useSettingsUrl;
