import { type SentryAppConfig } from '@betterment/js-runtime';

export function getAppConfig(): SentryAppConfig {
  // eslint-disable-next-line
  const appConfig: SentryAppConfig = (window as any).BMT.config;

  return {
    sentryApiToken: appConfig.sentryApiToken || '',
    sentryEnvironment: appConfig.sentryEnvironment || 'development',
    sentryRelease: appConfig.sentryRelease || '',
    user: appConfig.user,
    sentryWhitelistUrls: appConfig.sentryWhitelistUrls,
    sentryDisallowListUrls: appConfig.sentryDisallowListUrls,
    isReplayEnabled: false,
  };
}
