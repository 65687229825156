import $ from 'jquery';

$('.Donut-slice').on('mouseover', function updateDonut() {
  const sliceId = $(this).data('slice-id');
  const $donut = $(this).closest('.Donut');

  $donut.find('.Donut-holeTextStatic').css('opacity', 0);
  $donut.find(`.Donut-tooltipContent[data-slice-id='${sliceId}']`).css('opacity', 1);
});

$('.Donut-slice').on('mouseout', () => {
  $('.Donut-tooltipContent').css('opacity', 0);
  $('.Donut-holeTextStatic').css('opacity', 1);
});
