import Cookies from 'js-cookie';
import { Controller } from 'stimulus';

type AdaSettings = {
  toggleCallback: (isDrawerOpen: boolean) => void;
};

type MetaFields = {
  segment_visitor_id: string; // eslint-disable-line camelcase
  b4b_app: string; // eslint-disable-line camelcase
  [key: string]: string;
};

declare global {
  interface Window {
    adaSettings: AdaSettings;
    adaEmbed: {
      setMetaFields: (metaFields: MetaFields) => void;
    };
  }
}

export default class extends Controller {
  connect() {
    window.adaSettings = this.adaSettings;
  }

  private adaOpenCallback = (isDrawerOpen: boolean) => {
    if (isDrawerOpen) {
      window.adaEmbed.setMetaFields(this.metaFields);
    }
  };

  private get adaSettings(): AdaSettings {
    return {
      toggleCallback: this.adaOpenCallback,
    };
  }

  private get metaFields(): MetaFields {
    return {
      segment_visitor_id: Cookies.get('tt_visitor_id') || '',
      b4b_app: 'true',
      ...this.params,
    };
  }

  private get params() {
    return JSON.parse(this.data.get('params') || '{}') as Record<string, unknown>;
  }
}
