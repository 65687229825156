import $ from 'jquery';

function displayToggle() {
  $('[data-radio-toggle] input[type="radio"]')
    .on('change', e => {
      const $changeElement = $(e.currentTarget);
      const $toggleWrapperElement = $changeElement.closest('[data-radio-toggle]');
      const toggleValue = $toggleWrapperElement.data('radio-toggle');
      const oppositeToggleValue = $toggleWrapperElement.data('radio-toggle-opposite');
      const $toggleElement = $toggleWrapperElement.find(`input[type="radio"][value="${toggleValue.toString()}"]`);
      const $oppositeToggleElement =
        oppositeToggleValue &&
        $toggleWrapperElement.find(`input[type="radio"][value="${oppositeToggleValue.toString()}"]`);

      const $target = $($toggleWrapperElement.data('toggle-target'));
      const $oppositeTarget = $($toggleWrapperElement.data('toggle-opposite-target'));
      const $siblingElements = $toggleElement.siblings('input[type="radio"]');
      const hasNoOtherOptionSelected = $siblingElements.filter(':checked').length === 0;
      const hideTargetsBeforeSelection = $toggleWrapperElement.data('hide-targets-before-selection');

      const hideTargets = hideTargetsBeforeSelection && hasNoOtherOptionSelected;

      const toggleDisplayDirection = $toggleWrapperElement.data('display-direction');
      const hasToggleDisplayDirectionReversed = toggleDisplayDirection === 'reversed';

      if ($toggleElement.is(':checked')) {
        $target.toggleFormSection(!hasToggleDisplayDirectionReversed);
        if ($oppositeTarget != null) {
          $oppositeTarget.toggleFormSection(hasToggleDisplayDirectionReversed);
        }
      } else if ($oppositeToggleElement && !$oppositeToggleElement.is(':checked') && !hasNoOtherOptionSelected) {
        $target.hide();
        if ($oppositeTarget != null) {
          $oppositeTarget.hide();
        }
      } else if (hideTargets) {
        $target.hide();
        if ($oppositeTarget != null) {
          $oppositeTarget.hide();
        }
      } else {
        $target.toggleFormSection(hasToggleDisplayDirectionReversed);
        if ($oppositeTarget != null) {
          $oppositeTarget.toggleFormSection(!hasToggleDisplayDirectionReversed);
        }
      }
    })
    .trigger('change');
}

displayToggle();
$(document).on('page:change fields_added.nested_form_fields', displayToggle);
