import { Controller } from 'stimulus';
import Typed from 'typed.js';

export default class extends Controller {
  static values = {
    redirect: String,
    messages: {
      type: Array,
      default: ['Just a moment', 'This could take a few minutes...'],
    },
  };

  declare redirectValue: string;

  declare messagesValue: string[];

  #typed!: Typed;

  connect(): void {
    this.#typed = new Typed(this.element, {
      strings: this.messagesValue,
      typeSpeed: 40,
      backSpeed: 40,
      showCursor: true,
      onComplete: this.#onComplete,
    });
  }

  disconnect(): void {
    this.#typed.destroy();
  }

  #onComplete = (): void => {
    if (this.redirectValue) {
      window.location.assign(this.redirectValue);
    }
  };
}
