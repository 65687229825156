import { type ComponentType } from 'react';

import { ExampleApp } from './ExampleApp';

import { components as BetterMfaComponents } from '../../better-mfa/index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apps: Record<string, ComponentType<any>> = {
  Example: ExampleApp,
  SmsSetupFlow: BetterMfaComponents.SmsSetupFlow,
  TotpSetupFlow: BetterMfaComponents.TotpSetupFlow,
  SudoChallengeFlow: BetterMfaComponents.SudoChallengeFlow,
  LoginChallengeFlow: BetterMfaComponents.LoginChallengeFlow,
};

export function getReactApp(name: string) {
  const app = apps[name];

  if (!app) {
    throw new Error(`Could not resolve app with name: ${name}`);
  }

  return app;
}
