import $ from 'jquery';
import debounce from 'lodash/debounce';

function refreshForm(e) {
  const $form = $(this).closest('form');
  const attributes = $form.data('refresh') || {};
  const $eventTarget = $(e.target);
  const $spinnerTarget = attributes.spinner_target ? $(attributes.spinner_target) : $form;
  const shouldBlurInput = $eventTarget.data('keep-active') !== true;
  const shouldKeepSpinner = attributes.keep_spinner === true;
  const url = attributes.path || $form.attr('action');

  if ($eventTarget.data('initial-value') === e.target.value) {
    return;
  }

  // check if the tab key (keycode 9) was used to get to an empty field
  if (e.type === 'keyup' && e.target.value === '' && e.which === 9) {
    return;
  }

  if (e.type === 'change' && e.target.type === 'text' && e.target.value !== '' && !$(e.target).hasClass('datepicker')) {
    return;
  }

  // masked inputs emit a change event when the value is formatted, causing a refresh loop
  if (e.isTrigger && $eventTarget.data('mask')) {
    return;
  }

  $spinnerTarget.showSpinner();
  if (shouldBlurInput) {
    $eventTarget.blur();
  }

  $form.data(
    'pendingRequest',
    $.ajax({
      url,
      method: attributes.method || 'GET',
      data: $form.serialize(),
      dataType: 'script',
      beforeSend() {
        if ($form.data('pendingRequest')) {
          $form.data('pendingRequest').abort();
        }
      },
    }).always(function onComplete(xhr, textStatus) {
      const $refreshedInput = $(`#${e.target.id}`);
      if (shouldBlurInput && $refreshedInput.is('[type=text], textarea')) {
        const { length } = $refreshedInput.val();
        $refreshedInput.focus().get(0).setSelectionRange(length, length);
      }
      if (textStatus !== 'abort') {
        $form.removeData('pendingRequest');
        if (!shouldKeepSpinner) {
          $spinnerTarget.hideSpinner();
        }
      }
    })
  );
}

$(document).on('change', '[data-behavior-refresh-form]', refreshForm);
$(document).on('formRefresh', '[data-behavior-refresh-form]', refreshForm);
$(document).on('keyup', '[data-behavior-refresh-form]', debounce(refreshForm, 500));
