import LoginChallengeFlow from './apps/LoginChallengeFlow';
import SmsSetupFlow from './apps/SmsSetupFlow';
import SudoChallengeFlow from './apps/SudoChallengeFlow';
import TotpSetupFlow from './apps/TotpSetupFlow';

export const components = {
  SmsSetupFlow,
  TotpSetupFlow,
  SudoChallengeFlow,
  LoginChallengeFlow,
};
