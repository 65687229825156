import { Controller } from 'stimulus';

export default class extends Controller {
  close() {
    this.element.classList.remove('is-open');
    document.body.classList.remove('u-overflowHidden');
  }

  clickAway() {
    if (this.element.classList.contains('is-open')) {
      this.close();
    }
  }

  open() {
    this.element.classList.add('is-open');
    document.body.classList.add('u-overflowHidden');
  }
}
