import { createRoot } from 'react-dom/client';
import { Controller } from 'stimulus';

import { getReactApp } from '../apps';

export default class extends Controller {
  connect() {
    const App = getReactApp(this.name);
    const root = createRoot(this.element);
    root.render(<App {...this.initialProps} />);
  }

  get name(): string {
    return this.data.get('name')!;
  }

  get initialProps() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(this.data.get('initial-props')!);
  }

  get isRendered() {
    return this.data.get('rendered') === 'true';
  }

  markAsRendered() {
    this.data.set('rendered', 'true');
  }
}
