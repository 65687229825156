import React from 'react';

import { StepProps } from '@betterment/js-runtime';
import {
  Box,
  Button,
  CenteredLayout,
  Heading,
  Icon,
  IconButton,
  PancakeStack,
  Text,
  WorkflowHeader,
} from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import useSettingsUrl from '../../Shared/hooks/useSettingsUrl';
import { navigate } from '../../utils/path-helpers';

const SuccessStep: React.FC<StepProps> = ({ flowState }) => {
  const settingsUrl = useSettingsUrl();
  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading />
            <WorkflowHeader.Title>Two-Factor Authentication</WorkflowHeader.Title>
            <WorkflowHeader.Trailing>
              <IconButton
                label="back to settings"
                name="X"
                spacing="default"
                variant="ghost-alt"
                onClick={() => navigate(settingsUrl)}
              />
            </WorkflowHeader.Trailing>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Box>
              <Icon color="icon" name="CheckmarkCircle" size="large" />
              <Heading as="h1" css={{ marginBottom: 'space.400' }}>
                You're all set!
              </Heading>
              <Text as="p" css={{ marginBottom: 'space.400' }}>
                From now on, we'll use this number to send you verification codes when you sign in to your Betterment
                account.
              </Text>
              <Button type="button" onClick={() => navigate(flowState.returnToPath, { mfa_setup_success: true })}>
                Done
              </Button>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { SuccessStep };
