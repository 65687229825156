import { ApiErrors } from '@betterment/js-runtime';

export const convertApiErrorsToFormikMsg = (errors: ApiErrors) => {
  const errorMessage: { [key: string]: string } = {};
  Object.keys(errors.messages).forEach(key => {
    const [parsedMessage] = errors.messages[key];
    errorMessage[key] = parsedMessage;
  });

  return errorMessage;
};

export const mapFirstApiErrorMsgToFormikAttribute = (errors: ApiErrors, attribute: string) => {
  const errorMessage: { [key: string]: string } = {};
  const firstApiErrorMessage = Object.values(errors.messages).flat()[0];
  errorMessage[attribute] = firstApiErrorMessage;
  return errorMessage;
};
