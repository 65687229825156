import React, { useState } from 'react';

import { useFlowState, useMutation } from '@betterment/js-runtime';
import {
  Box,
  Button,
  CenteredLayout,
  Heading,
  IconButton,
  Logo,
  PancakeStack,
  Text,
  TextColorToken,
  WorkflowHeader,
} from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import { getAppConfig } from '../../../b4b/config';
import { NON_PROD_ENVIRONMENTS, SmsDeliveryMethods, VERIFICATION_CODE_LENGTH } from '../../Shared/data';
import useSettingsUrl from '../../Shared/hooks/useSettingsUrl';
import URLBuilder from '../../Shared/url-builder';
import { mapFirstApiErrorMsgToFormikAttribute } from '../../utils/form-helpers';
import { navigate } from '../../utils/path-helpers';

const initialErrorState: {
  messageColor: TextColorToken;
  messageContent: string;
} = {
  messageColor: 'text',
  messageContent: '',
};

const SmsStartStep: React.FC = () => {
  const { sentryEnvironment } = getAppConfig();
  const {
    flowState: { values },
    goToNextStep,
  } = useFlowState<{
    mfaPhoneNumber: string;
    deliveryMethod: SmsDeliveryMethods;
  }>();
  const settingsUrl = useSettingsUrl();

  const [errorState, setErrorState] = useState(initialErrorState);
  const [sendSmsCode, { loading: isSendingCode }] = useMutation(URLBuilder.postSudoChallengeSend.absolute(), {
    method: 'post',
  });
  const sendVerificationCode = async (deliveryMethod: SmsDeliveryMethods) => {
    const response = await sendSmsCode({ deliveryMethod });
    if (response.errors) {
      setErrorState({
        messageColor: 'text.critical',
        messageContent: mapFirstApiErrorMsgToFormikAttribute(response.errors, 'base').base,
      });
    } else {
      goToNextStep({ deliveryMethod });
    }
  };

  return (
    <>
      <Helmet>
        <title>Authenticate your account</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading>
              <Logo type="signature" css={{ height: 'size.300' }} />
            </WorkflowHeader.Leading>
            <WorkflowHeader.Title>Authenticate your account | 1 of 2</WorkflowHeader.Title>
            <WorkflowHeader.Trailing>
              <IconButton
                label="back to settings"
                name="X"
                spacing="default"
                variant="ghost-alt"
                onClick={() => navigate(settingsUrl)}
              />
            </WorkflowHeader.Trailing>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1">Your security is important to us</Heading>
            <Box css={{ marginY: 'space.100' }}>
              <Text>
                We need to re-authenticate your account before you continue. In order to do so, we will send a temporary{' '}
                {VERIFICATION_CODE_LENGTH}-digit verification code to your 2FA number {values.mfaPhoneNumber}.
              </Text>
              {NON_PROD_ENVIRONMENTS.includes(sentryEnvironment) && (
                <Text as="p" css={{ marginTop: 'space.200' }}>
                  In development no messages are sent, please proceed and the verification code will default to 123456.
                </Text>
              )}
            </Box>

            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                marginY: 'space.200',
              }}
            >
              <Button disabled={isSendingCode} onClick={() => void sendVerificationCode(SmsDeliveryMethods.sms)}>
                Text me
              </Button>
              <Button
                variant="linkPrimary"
                css={{ fontWeight: 'medium', marginLeft: 'space.200' }}
                disabled={isSendingCode}
                onClick={() => void sendVerificationCode(SmsDeliveryMethods.voice)}
              >
                Call me instead
              </Button>
            </Box>
            <Box>
              <Text color={errorState.messageColor}>{errorState.messageContent}</Text>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { SmsStartStep };
