import $ from 'jquery';
import TestTrack from 'test_track_js_client';

initializeAnalytics();

function initializeAnalytics() {
  initializeTestTrack();
  initializeClickEventListener();
}

function initializeTestTrack() {
  void TestTrack.initialize({
    analytics: {
      trackAssignment(visitorId, assignment, callback) {
        window.analytics?.track(
          'SplitAssigned',
          {
            splitName: assignment.getSplitName(),
            splitVariant: assignment.getVariant(),
            splitContext: assignment.getContext(),
          },
          {},
          () => callback(true)
        );
      },
    },

    onVisitorLoaded(visitor) {
      window.analytics?.identify(visitor.getId());
    },
  });
}

function initializeClickEventListener() {
  $(document).on('click', '[data-track-event]', e => {
    var data = $(e.currentTarget).data();
    if (!data) {
      return;
    }

    handleSegmentEvent(data);
  });
}

function handleSegmentEvent(data) {
  var eventProps = getEventProperties(data);
  if (eventProps) {
    window.analytics?.track(eventProps.eventName, eventProps.props);
  }
}

// eslint-disable-next-line consistent-return
function getEventProperties(data) {
  const eventName = data.trackEvent;
  const props = {
    name: data.trackName,
    flow: data.trackFlow,
    module: data.trackModule,
    application: 'BettermentForBusiness',
    client: 'Web',
  };

  if (
    (eventName === 'ElementClicked' || eventName === 'ElementViewed') &&
    props.application &&
    props.name &&
    props.client
  ) {
    return {
      eventName,
      props,
    };
  }
}
