/// <reference types="vite/client" />

import { type SentryAppConfig, StimulusAppBootstrapper } from '@betterment/js-runtime';
import { registerSambaControllers } from '@betterment/samba-stimulus';
import { type Definition } from 'stimulus';
import { definitionsFromGlob } from 'stimulus-vite-helpers';

const controllers = {
  ...import.meta.glob('./*-controller.ts', { eager: true }),
  ...import.meta.glob('./*-controller.tsx', { eager: true }),
};

const definitions: Definition[] = definitionsFromGlob(controllers);

export async function startStimulus(config: SentryAppConfig) {
  const bootstrapper = new StimulusAppBootstrapper(definitions, config);
  const application = await bootstrapper.initialize();

  registerSambaControllers(application);
}
