import * as Turbo from '@hotwired/turbo';

export function startTurbo() {
  /**
   * Turbo Drive is disabled, which makes Turbo opt-in.
   * Links and forms will behave normally by default.
   */
  Turbo.session.drive = false;
  Turbo.start();

  /**
   * When Turbo Frame encounters an unhandled 500 error, we'll
   * show the error page content.
   */
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  document.addEventListener('turbo:frame-missing', async event => {
    const { response } = event.detail;

    if (!event.defaultPrevented && response.status >= 500) {
      event.preventDefault();

      const responseHTML = await response.clone().text();
      Turbo.visit(window.location.href, { response: { responseHTML } });
    }
  });
}
