import $ from 'jquery';

$('[data-behavior="use_utility"]')
  .on('change', e => {
    var $checkbox = $(e.currentTarget);
    var target = `#${$checkbox.data('target-id')}`;

    if ($checkbox.is(':checked')) {
      $(target).find('td.js-achTotal').addClass('u-displayNone');
      $(target).find('td.js-achMinusUtility').removeClass('u-displayNone');
    } else {
      $(target).find('td.js-achMinusUtility').addClass('u-displayNone');
      $(target).find('td.js-achTotal').removeClass('u-displayNone');
    }
  })
  .trigger('change');
