import React from 'react';

import { DataFetchProvider } from '@betterment/js-runtime';
import { BrowserRouter as Router } from 'react-router-dom';

import Controller from '../components/SudoChallengeFlow/Controller';
import { InitialProps } from '../components/SudoChallengeFlow/data';
import { buildAppPath } from '../utils/path-helpers';

const SudoChallengeFlowApp: React.FC<InitialProps> = initialProps => {
  const baseName = buildAppPath('/better_mfa/sudo_challenge');
  return (
    <DataFetchProvider>
      <Router basename={baseName}>
        <Controller {...initialProps} />
      </Router>
    </DataFetchProvider>
  );
};

export default SudoChallengeFlowApp;
