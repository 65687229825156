import React from 'react';

import { DataFetchProvider } from '@betterment/js-runtime';
import { BrowserRouter as Router } from 'react-router-dom';

import Controller from '../components/SmsSetupFlow/Controller';
import { buildAppPath } from '../utils/path-helpers';

const SmsSetupFlow: React.FC = () => {
  const baseName = buildAppPath('/better_mfa/sms_setup');

  return (
    <DataFetchProvider>
      <Router basename={baseName}>
        <Controller />
      </Router>
    </DataFetchProvider>
  );
};

export default SmsSetupFlow;
