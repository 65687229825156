const eligibleOptionNo = document.getElementById('participant_eligibility_update_eligible_false');

if (eligibleOptionNo != null) {
  const willBeEligibleOptionYes = document.getElementById('participant_eligibility_update_will_become_eligible_true');
  const willBeEligibleOptionNo = document.getElementById('participant_eligibility_update_will_become_eligible_false');
  const eligibleOnInput = document.getElementById('participant_eligibility_update_entry_on');

  const disableEligibleOnInput = () => {
    eligibleOnInput.disabled = true;
    eligibleOnInput.classList.add('disabled');
    eligibleOnInput.value = '';
  };

  willBeEligibleOptionNo.addEventListener('click', () => {
    disableEligibleOnInput();
  });

  willBeEligibleOptionYes.addEventListener('click', () => {
    eligibleOnInput.disabled = false;
    eligibleOnInput.classList.remove('disabled');
  });

  if (eligibleOptionNo.checked === true && willBeEligibleOptionNo.checked === true) {
    disableEligibleOnInput();
  }
}
