import * as Yup from 'yup';

export const NON_PROD_ENVIRONMENTS = ['development', 'demo', 'stage'];
export const VERIFICATION_CODE_LENGTH = 6;

export enum Modes {
  sms = 'sms',
  totp = 'totp',
}

export enum SmsDeliveryMethods {
  sms = 'sms',
  voice = 'voice',
}

export const VerificationCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required('is required')
    .matches(/^[0-9]+$/, `Must be exactly ${VERIFICATION_CODE_LENGTH} digits`)
    .length(VERIFICATION_CODE_LENGTH, `Must be exactly ${VERIFICATION_CODE_LENGTH} digits`),
});
