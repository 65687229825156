import React from 'react';

import { Box, Spinner } from '@betterment/samba-react';

export const FullPageLoader: React.FC = () => (
  <Box
    css={{
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spinner size="lg" variant="primary" />
  </Box>
);
