import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['missing'];

  declare missingTarget: HTMLTemplateElement;

  onMissing(event: Event) {
    event.preventDefault();
    const errorContent = this.missingTarget.content.cloneNode(true);
    this.element.replaceChildren(errorContent);
  }
}
