import { MouseEvent, useEffect, useState } from 'react';

import { StepProps, useQuery } from '@betterment/js-runtime';
import {
  Banner,
  Box,
  Button,
  CenteredLayout,
  Heading,
  IconButton,
  Label,
  Link,
  Logo,
  PancakeStack,
  Text,
  WorkflowHeader,
} from '@betterment/samba-react';
import copy from 'copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import { Helmet } from 'react-helmet';

import { TotpSetupNewResponse } from './data';

import useSettingsUrl from '../../Shared/hooks/useSettingsUrl';
import urlBuilder from '../../Shared/url-builder';
import { TestIDProps } from '../../testing/test-id-props';
import { navigate } from '../../utils/path-helpers';

const StartStep = ({ goToNextStep, testID }: StepProps & TestIDProps) => {
  const {
    data: newQueryData,
    loading: newQueryLoading, // eslint-disable-line @typescript-eslint/naming-convention
    errors: newQueryErrors,
    networkError: newQueryNetworkError,
  } = useQuery<TotpSetupNewResponse>(urlBuilder.getTotpSetupNew.absolute());
  const [totpProvisioningURI, setTOTPProvisioningURI] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const settingsUrl = useSettingsUrl();
  const clickContinue = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    goToNextStep();
  };
  useEffect(() => {
    if (newQueryLoading) {
      return;
    }
    if (newQueryErrors) {
      const mError = 'System error';
      setError(mError);
      return;
    }
    const { totpProvisioningUri: newTOTPProvisioningURI } = newQueryData as TotpSetupNewResponse;
    if (newTOTPProvisioningURI === undefined) {
      const mError = 'System error';
      setError(mError);
      return;
    }
    setTOTPProvisioningURI(newTOTPProvisioningURI);
  }, [newQueryData, newQueryLoading, newQueryErrors, newQueryNetworkError]);

  function totpKey(url: string): string | null {
    const match = url.match(/secret=([^&]+)/);
    return match ? decodeURIComponent(match[1]) : null;
  }
  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading>
              <Logo type="signature" css={{ height: 'size.300' }} />
            </WorkflowHeader.Leading>
            <WorkflowHeader.Title>Two-Factor Authentication | 1 of 2</WorkflowHeader.Title>
            <WorkflowHeader.Trailing>
              <IconButton
                label="back to settings"
                name="X"
                spacing="default"
                variant="ghost-alt"
                onClick={() => navigate(settingsUrl)}
              />
            </WorkflowHeader.Trailing>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout size="medium" css={{ gap: 'space.400', padding: 'space.400' }}>
            <Heading as="h1" data-testid={testID}>
              Configure authenticator app.
            </Heading>
            <Box>
              <Text>
                {!!error && (
                  <Banner
                    css={{ marginBottom: 'space.400' }}
                    variant="critical"
                    data-testid={testID ? `${testID}-error` : ''}
                  >
                    <Banner.Content>
                      <Text as="p">{error}</Text>
                    </Banner.Content>
                    <Banner.Close onClick={() => setError(null)} />
                  </Banner>
                )}
                <p>
                  To set up on your phone, download your preferred authenticator app from the App Store or Google Play
                  Store. Then, open the app and scan the QR code below:
                </p>
                {!!totpProvisioningURI && (
                  <>
                    <Box
                      css={{
                        borderColor: 'border',
                        borderStyle: 'solid',
                        borderWidth: 'thin',
                        padding: 'space.400',
                        marginY: 'space.200',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <QRCodeSVG
                        value={totpProvisioningURI}
                        size={200}
                        data-testid={testID ? `${testID}-qr-code` : null}
                      />
                    </Box>
                    <Box css={{ marginY: 'space.400' }}>
                      <Label css={{ marginBottom: 'space.100' }} htmlFor="totpCode">
                        Or enter the code manually:
                      </Label>
                      <Box
                        css={{
                          backgroundColor: 'primitives.grey.10',
                          color: 'text.weak',
                          padding: 'space.200',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        data-testid={testID ? `${testID}-totp-key` : null}
                      >
                        {totpKey(totpProvisioningURI)}
                        <Button
                          variant="ghost"
                          spacing="compact"
                          onClick={() => copy(totpKey(totpProvisioningURI) || '')}
                          data-testid={testID ? `${testID}-copy` : null}
                        >
                          Copy
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
                <Box css={{ display: 'flex', alignItems: 'center' }}>
                  <Button type="button" onClick={clickContinue} data-testid={testID ? `${testID}-continue` : null}>
                    Continue
                  </Button>
                  <Link href={urlBuilder.getSmsSetupFlow()} variant="primary" css={{ marginLeft: 'space.200' }}>
                    Confirm with phone number
                  </Link>
                </Box>
              </Text>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { StartStep };
