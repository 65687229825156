import qs from 'query-string';

export function buildAppPath(path: string, params?: { [key: string]: string | number | boolean | string[] }) {
  const query = params ? `?${qs.stringify(params, { arrayFormat: 'bracket' })}` : '';
  return `${path}${query}`;
}

export function navigate(path: string, params?: { [key: string]: string | number | boolean }) {
  window.location.assign(buildAppPath(path, params));
}
