import React from 'react';

import { FlowStateProvider, Step } from '@betterment/js-runtime';

import { InitialProps } from './data';
import { DoneStep } from './DoneStep';
import { StartStep } from './StartStep';
import { TotpVerifyStep } from './TotpVerifyStep';
import { VerifyStep } from './VerifyStep';

import { Modes, SmsDeliveryMethods } from '../../Shared/data';

const Controller: React.FC<InitialProps> = ({ mfaPhoneNumber, mode }) => {
  if (mode === Modes.totp) {
    return (
      <FlowStateProvider>
        <Step name="verify" component={TotpVerifyStep} />
        <Step name="done" component={DoneStep} />
      </FlowStateProvider>
    );
  }
  const initialValues = {
    deliveryMethod: SmsDeliveryMethods.sms,
    mfaPhoneNumber,
  };
  return (
    <FlowStateProvider initialValues={initialValues}>
      <Step name="start" component={StartStep} />
      <Step name="verify" component={VerifyStep} />
      <Step name="done" component={DoneStep} />
    </FlowStateProvider>
  );
};

export default Controller;
