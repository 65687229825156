/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */

import * as Sentry from '@sentry/browser';
import { Controller } from 'stimulus';

declare var zE: any;

export default class extends Controller {
  connect() {
    try {
      this.load();
    } catch {
      setTimeout(() => this.load(), 2000);
    }
  }

  private load() {
    zE('messenger:on', 'open', () => this.auth());
  }

  private auth() {
    zE('messenger', 'loginUser', (callback: (data: string) => any) => {
      fetch('/ultimate_authentication')
        .then(response => response.text())
        .then(data => callback(data))
        .catch(error => Sentry.captureException(error));
    });
  }
}
