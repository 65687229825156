import { MouseEvent, useState } from 'react';

import { StepProps, useMutation } from '@betterment/js-runtime';
import {
  Box,
  Button,
  CenteredLayout,
  Checkbox,
  Heading,
  Icon,
  Logo,
  PancakeStack,
  Text,
  WorkflowHeader,
} from '@betterment/samba-react';
import { Helmet } from 'react-helmet';

import URLBuilder from '../../Shared/url-builder';
import { TestIDProps } from '../../testing/test-id-props';
import { navigate } from '../../utils/path-helpers';

const DoneStep = ({ testID }: StepProps & TestIDProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const [createTrustedDevice] = useMutation<{}>(URLBuilder.postTrustedDevice.absolute(), {
    method: 'post',
  });
  const onContinue = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const response = (await createTrustedDevice({ isChecked })) as { redirectUri: string };
    navigate(response.redirectUri);
  };

  return (
    <>
      <Helmet>
        <title>Login challenge</title>
      </Helmet>
      <PancakeStack css={{ background: 'background.surface' }}>
        <PancakeStack.Header css={{ borderBottom: 'solid 1px', borderColor: 'border' }}>
          <WorkflowHeader>
            <WorkflowHeader.Leading>
              <Logo type="signature" css={{ height: 'size.200' }} />
            </WorkflowHeader.Leading>
          </WorkflowHeader>
        </PancakeStack.Header>
        <PancakeStack.Main>
          <CenteredLayout
            size="medium"
            css={{
              gap: 'space.400',
              padding: 'space.400',
              marginBottom: 'space.400',
            }}
          >
            <Box>
              <Icon color="icon" name="CheckmarkCircle" size="large" />
              <Heading as="h1" data-testid={testID} css={{ marginBottom: 'space.400' }}>
                You're all set!
              </Heading>

              <Text as="p" css={{ marginBottom: 'space.200' }}>
                When you log in to your Betterment account, we will ask you to verify your identity unless you ask us to
                remember your device. We recommend this only if it's your personal device and not publicly shared.
              </Text>
              <Checkbox css={{ marginBottom: 'space.400' }}>
                <Checkbox.Control
                  appearance="default"
                  aria-label="Remember this device"
                  onCheckedChange={handleChange}
                />
                <Checkbox.Label>Remember me on this device</Checkbox.Label>
              </Checkbox>
              <Button
                type="button"
                onClick={(event: MouseEvent<HTMLButtonElement>) => void onContinue(event)}
                data-testid={testID ? `${testID}-continue` : null}
              >
                Continue
              </Button>
            </Box>
          </CenteredLayout>
        </PancakeStack.Main>
      </PancakeStack>
    </>
  );
};

export { DoneStep };
