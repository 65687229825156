import { Controller } from 'stimulus';

export default class extends Controller {
  declare nameValue: string;

  declare selectorValue: string;

  static values = {
    name: String,
    selector: String,
  };

  toggle() {
    this.#targetElement.classList.toggle(this.nameValue);
  }

  get #targetElement() {
    if (this.selectorValue) {
      const element = document.querySelector(this.selectorValue);
      if (!element) throw new Error(`No such element: ${this.selectorValue}`);
      return element;
    }
    return this.element;
  }
}
