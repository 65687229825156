import React, { useEffect } from 'react';

import { useFlowState } from '@betterment/js-runtime';

import { FullPageLoader } from './FullPageLoader';

export const RedirectToPreviousStep: React.FC<{
  fallback?: React.ReactElement;
}> = ({ fallback }) => {
  const { goToPreviousStep } = useFlowState();

  useEffect(() => {
    goToPreviousStep();
  }, [goToPreviousStep]);

  return fallback ?? <FullPageLoader />;
};
